
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';

import { getSessionStorage, removeSessionStorage, setSessionStorage } from '@/utils/utils';

import {
	mapGettersOfCase,
	SET_CASE_LIST_INFO,
	SET_USER_PARTNER_INFO,
	mapMutationsOfCase,
	CASE_CATEGORY_GROUP_ID,
	SET_SORTING_OPTIONS,
	IS_JOB_SEEKING,
	mapActionsOfCase,
	LOAD_AFFILIATE_UNIV_EXPOSURE_DATA,
} from '@/store/case';
import { mapActionsOfEduList, RESET_KEYWORD } from '@/store/edu/list';
import { GET_ROUTE_NAME, mapGettersOfLoading } from '@/store/loading';
import { mapGettersOfUserVoice, GET_SHOW_USER_VOICE_ALERT } from '@/store/userVoice';

import CaseList from '@/components/case/list/CaseList.vue';
import AppLinkToast from '@/components/case/list/main/atoms/AppLinkToast.vue';
import CaseListSogangToast from '@/components/case/list/main/atoms/CaseListSogangToast.vue';
import CommunityTimeline from '@/components/case/timeline/CommunityTimeline.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import caseMixin from '@/mixins/caseMixin';
import layoutMixin from '@/mixins/layoutMixin';
import userVoiceAlertMixin from '@/mixins/userVoiceAlertMixin';

export default {
	name: 'Index',
	mixins: [layoutMixin, caseMixin, appCommandMixin, userVoiceAlertMixin],
	async fetch() {
		if (this.isUser) {
			await Promise.all([this.getUserPartnerInfo(), this[LOAD_AFFILIATE_UNIV_EXPOSURE_DATA]()]);
		}
	},
	fetchOnServer: false,
	async asyncData({ store: { commit, dispatch }, route, redirect }) {
		try {
			const keyword = route.params.keyword;
			if (typeof keyword !== 'undefined') {
				redirect(`/search/index/${keyword}`);
			} else {
				commit(`case/${SET_CASE_LIST_INFO}`, { keyword: null });
				await dispatch(`edu/list/${RESET_KEYWORD}`);
			}
		} catch (error) {
			console.error('job-questions asyncData error: ', error);
		}
	},
	computed: {
		...mapGettersOfCase([CASE_CATEGORY_GROUP_ID, IS_JOB_SEEKING]),
		...mapGetters(['isUser', 'userNo']),
		...mapGettersOfLoading([GET_ROUTE_NAME]),
		...mapGettersOfUserVoice([GET_SHOW_USER_VOICE_ALERT]),
		isLoaded() {
			return typeof this[CASE_CATEGORY_GROUP_ID] !== 'undefined';
		},
		isTimeline() {
			return this.$route.name === 'job-questions-keyword' && !this.$route.query.feed;
		},
	},
	watch: {
		isTimeline() {
			if (this.isTimeline) {
				this[RESET_KEYWORD]();
			}
		},
		shouldShowClassPtBanner() {
			this.setEduCharm();
		},
	},
	mounted() {
		window.addEventListener('pageshow', this.restoreScrollPosition);
		window.addEventListener('scroll', this.debouncedSaveScrollPosition, { passive: true });
	},
	beforeDestroy() {
		window.removeEventListener('pageshow', this.restoreScrollPosition);
		window.removeEventListener('scroll', this.debouncedSaveScrollPosition);
	},
	methods: {
		...mapMutationsOfCase([SET_USER_PARTNER_INFO, SET_SORTING_OPTIONS, SET_CASE_LIST_INFO]),
		...mapActionsOfCase([LOAD_AFFILIATE_UNIV_EXPOSURE_DATA]),
		...mapActionsOfEduList([RESET_KEYWORD]),
		async getUserPartnerInfo() {
			try {
				const {
					data: {
						organization_type,
						is_affiliate,
						is_re_affiliated,
						is_user_using_affiliate,
						affiliated_before,
					},
				} = await this.$api.case.checkAffiliate();

				this[SET_USER_PARTNER_INFO]({
					organizationType: organization_type,
					isAffiliate: is_affiliate,
					isReAffiliated: is_re_affiliated,
					isUserUsingAffiliate: is_user_using_affiliate,
					isUserAffiliatedBefore: affiliated_before,
				});
			} catch (error) {
				console.error('getUserPartnerInfo error: ', error);
			}
		},
		debouncedSaveScrollPosition: debounce(function () {
			this.saveScrollPosition();
		}, 150),
		saveScrollPosition() {
			setSessionStorage('scrollPosition', window.scrollY);
		},
		setScrollPosition() {
			if (document.body.scrollHeight === 0) {
				this.$nextTick(() => {
					this.setScrollPosition();
				});
				return;
			}

			window.scrollTo(0, getSessionStorage('scrollPosition') || 0);
			removeSessionStorage('scrollPosition');
		},
		restoreScrollPosition(event) {
			if (event.persisted) {
				this.setScrollPosition();
			}
		},
	},
	head() {
		const paramKeyword = this.$nuxt.$route.params.keyword;
		const keyword = typeof paramKeyword !== 'undefined' ? `${paramKeyword} ` : '';

		return {
			title: `${keyword} 커뮤니티 | 코멘토`,
			meta: [
				{
					hid: 'description',
					name: 'description',
					content: `${keyword}관련 현직자만 알고있는 취업정보를 읽고 올바르게 취업을 준비하세요.`,
				},
				{
					hid: 'og:description',
					name: 'og:description',
					content: `${keyword}관련 현직자만 알고있는 취업정보를 읽고 올바르게 취업을 준비하세요.`,
				},
				{
					hid: 'og:image',
					property: 'og:image',
					content: 'https://cdn.comento.kr/images/meta/meta-landing.jpg',
				},
				{
					hid: 'og:url',
					property: 'og:url',
					content: `https://comento.kr${this.$nuxt.$route.fullPath}`,
				},
				{
					hid: 'keywords',
					name: 'keywords',
					content: `현직자, 취업 질문, 꿀팁, 멘토링, 직무, 회사 분위기, 업무 내용, 스펙, 토익, ${keyword}`,
				},
			],
		};
	},
	meta: {
		isShowSubHeader: true,
		fluid: true,
		isFooterSlim: true,
	},
	middleware: 'check-update-profile',
	components: {
		CommunityTimeline,
		CaseList,
		CaseListSogangToast,
		AppLinkToast,
	},
};
